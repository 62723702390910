import { Component } from "react";
import Select from "../components/Select";
import { client } from "../oauth";
import { IoChevronBack } from "react-icons/io5";
import { ThreeDots } from "react-loader-spinner";

const botsArray = [
  { content: "Martı BOT", id: "marti", image: "https://marti.barbaros-dev.xyz/static/media/Mart%C4%B1%20BOT.0c91e4bd51a9ebdec9d6.png" },
  { content: "Sinek BOT", id: "sinek", image: "https://images-ext-2.discordapp.net/external/d-9TaFGREwDFHiiY8OCqaSVFQ0jUdWwsBGkAPw17J80/%3Fsize%3D1024/https/cdn.discordapp.com/avatars/707302701398097971/dd966c4d8800f81b2bb0ca34d427c829.png?width=701&height=701" }
]

const categories = [
  { content: "Öneri", id: "oneri" },
  { content: "Geribildirim", id: "geribildirim" }
]

export default class CreateFeedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bot: "",
      category: "",
      feedback: "",
      loading: false
    }
    this.setBot = this.setBot.bind(this)
    this.setCategory = this.setCategory.bind(this)
    this.send = this.send.bind(this)
  }

  setBot(bot) {
    this.setState({ bot: bot })
  }

  setCategory(category) {
    this.setState({ category: category })
  }

  async send() {
    if(!this.state.feedback || !this.state.bot || !this.state.category) return alert("Lütfen gerekli olan her yeri doldurunuz")
    document.getElementById("card").style.justifyContent = "center"
    this.setState({ loading: true })
    const res = await client.getUser(localStorage.getItem("token"))
    fetch("https://catnip-boggy-neon.glitch.me/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Key": "l8KsHVkC4Xa3v11"
      },
      body: JSON.stringify({
        feedback: this.state.feedback,
        bot: this.state.bot.id,
        category: this.state.category.id,
        user: res.id
      })
    }).then(res => res.json()).then(data => {
      window.location = `${window.location.origin}/requests?id=${data.ticket.id}`
    })
  }

  componentDidMount() {
    const urlparams = new URLSearchParams(window.location.search)
    if(urlparams.has("bot") && botsArray.find(bot => bot.id === urlparams.get("bot"))) {
      this.setState({ bot: botsArray.find(bot => bot.id === urlparams.get("bot")) })
    }
    if(urlparams.has("category") && categories.find(category => category.id === urlparams.get("category"))) {
      this.setState({ category: categories.find(category => category.id === urlparams.get("category")) })
    }
    if(urlparams.has("content")) {
      this.setState({ feedback: urlparams.get("content") })
    }
  }

  render() {
    return(
      <div className="container">
        <h1 className="title">Geribildirim Gönder</h1>
        <div id="card" className="card">
          <ThreeDots color="#000" visible={this.state.loading} />
          <div onClick={() => {window.location = `${window.location.origin}/`}} className="backButton">
            <div className="backButtonContent">
              <IoChevronBack className="backButtonIcon" size={20}/>
              Geri
            </div>
          </div>
          <div style={{ display: this.state.loading ? "none" : null }} className="inputContainer">
            <Select selected={this.state.bot} setValue={this.setBot} defaultText="Botu seçin:" options={botsArray}
            />
            <Select selected={this.state.category} setValue={this.setCategory} defaultText="Ne hakkında?" options={categories}
            />
            <textarea value={this.state.feedback} spellCheck="false" onChange={e => this.setState({ feedback: e.target.value })} placeholder="Geribildirim açıklaması" className="feedbackTextArea"></textarea>
          </div>
          <button style={{ display: this.state.loading ? "none" : "block" }} onClick={this.send} className="sendBtn">Gönder</button>
        </div>
        <style>{`
          .feedbackTextArea::placeholder {
            color: #000;
            font-weight: 700;
          }

          .sendBtn:hover {
            filter: brightness(0.6);
          }
        `}</style>
      </div>
    )
  }
}