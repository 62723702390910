import { Component } from "react";
import { formatDistanceToNow } from "date-fns";
import { tr } from "date-fns/locale";

export default class TicketContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timestamp: formatDistanceToNow(this.props.ticket.messages[this.props.ticket.messages.length - 1].timestamp, { addSuffix: true, locale: tr })
    }
    this.codeToString = this.codeToString.bind(this)
  }

  codeToString(code) {
    switch (code) {
      case "sinek":
        return "Sinek BOT"
      case "marti":
        return "Martı BOT"
      case "oneri":
        return "Öneri"
      case "geribildirim":
        return "Geribildirim"
      default:
        return
    }
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({ timestamp: formatDistanceToNow(this.props.ticket.messages[this.props.ticket.messages.length - 1].timestamp, { addSuffix: true, locale: tr }) })
    }, 1000);
  }

  render() {
    return(
      <div onClick={() => {
        window.location = `${window.location.origin}/requests?id=${this.props.ticket.id}`
      }} className="ticket">
        <span className="ticketCategory">{`${this.codeToString(this.props.ticket.id.split(".")[0])} - ${this.codeToString(this.props.ticket.bot)}`}</span>
        <span className="lastMessage">{`${this.props.ticket.messages[this.props.ticket.messages.length - 1].author.username}: ${this.props.ticket.messages[this.props.ticket.messages.length - 1].content}`}</span>
        <span className="ticketTimestamp">{this.state.timestamp}</span>
        <style>{`
          .ticket {
            background: #b8b8b8;
            user-select: none;
            cursor: pointer;
            width: 78%;
            padding: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-radius: 12px;
            transition: 0.2s;
            margin-bottom: 20px;
          }

          .ticket:first-child {
            margin-top: 20px;
          }

          .ticket:hover {
            filter: brightness(0.9);
          }

          .ticketCategory {
            color: #000;
            font-weight: 700;
            position: relative;
            padding-bottom: 10px;
          }

          .lastMessage {
            color: #555555;
            font-weight: 700;
            overflow: hidden;
            white-space: no-wrap;
            text-overflow: ellipsis;
            padding-bottom: 10px;
          }

          .ticketTimestamp {
            color: #333333;
            font-weight: 700;
            text-align: right;
          }
        `}</style>
      </div>
    )
  }
}