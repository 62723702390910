import { Component } from "react";
import Login from "./pages/Login";
import Feedbacks from "./pages/Feedbacks";
import CreateFeedback from "./pages/CreateFeedback";
import Feedback from "./pages/Feedback";

export default class App extends Component {
  render() {
    if(!localStorage.getItem("token")) return <Login/>
    else if(window.location.pathname === "/createRequest") return <CreateFeedback/>
    else if(window.location.pathname === "/requests") return <Feedback/>
    else return <Feedbacks/>
  }
}