import { Component } from "react";
import { formatDistanceToNow } from "date-fns";
import { tr } from "date-fns/locale";
import { IoSend } from "react-icons/io5";

export default class MessageBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timestamp: formatDistanceToNow(this.props.message.timestamp, { addSuffix: true, locale: tr })
    }
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({ timestamp: formatDistanceToNow(this.props.message.timestamp, { addSuffix: true, locale: tr }) })
    }, 1000);
  }

  render() {
    return(
      <div className="messageBox">
        <span className="messageAuthor">{this.props.message.author.username}</span>
        <pre className="messageContent">{this.props.message.content}</pre>
        <div className="bottomRight">
          <span className="messageTimestamp">{this.state.timestamp}</span>
          <IoSend size={12} color="#444444" className="sendingIcon" style={{ display: this.props.message.sending ? "block" : "none" }}  />
        </div>
        <style>{`
          .messageBox {
            background: #b8b8b8;
            width: 78%;
            padding: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-radius: 12px;
            transition: 0.2s;
            margin-bottom: 20px;
          }

          .messageBox:hover {
            filter: brightness(0.9);
          }

          .messageAuthor {
            color: #000;
            font-weight: 700;
            position: relative;
            padding-bottom: 0px;
          }

          .messageContent {
            color: #555555;
            font-weight: 700;
            padding-top: 10px;
            padding-bottom: 10px;
            word-wrap: break-word;
            white-space: break-spaces;
            font-family: Nunito;
          }

          .messageTimestamp {
            color: #333333;
            font-weight: 700;
          }

          .bottomRight {
            display: flex;
            justify-content: end;
            align-items: center;
          }

          .sendingIcon {
            margin-left: 10px;
            animation: bright 1s infinite;
          }

          .messageAuthorAnimation {
            background: linear-gradient(
              90deg,
              #a5a5a5 0%,
              #a5a5a5 40%,
              #ddd 50%,
              #ddd 55%,
              #a5a5a5 65%,
              #a5a5a5 100%
            );
            background-size: 300%;
            border-radius: 12px;
            width: 120px;
            animation: loading 1.5s infinite;
          }

          @keyframes loading {
            from {
              background-position: 100%;
            }
            to {
              background-position: 0%;
            }
          }

          @keyframes bright {
            0% {
              filter: brightness(0.1);
            }
            50% {
              filter: brightness(1);
            }
            100% {
              filter: brightness(0.1);
            }
          }
        `}</style>
      </div>
    )
  }
}