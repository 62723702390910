import React, { useState, useEffect, useRef } from "react";
import { FaChevronDown } from "react-icons/fa";

const Select = ({ defaultText, options, setValue, selected }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [borderRadius, setBorderRadius] = useState("12px");
  const [borderColor, setBorderColor] = useState("#b8b8b8");
  const [transform, setTransform] = useState("rotate(0deg)");

  const selectRef = useRef(null);

  const toggle = () => {
    if (!showOptions) {
      setBorderRadius("12px 12px 0px 0px");
      setTransform("rotate(180deg)");
      setBorderColor("rgba(0, 0, 0, 0.2)")
    } else {
      setBorderRadius("12px");
      setTransform("rotate(0deg)");
      setBorderColor("#b8b8b8")
    }

    setShowOptions(!showOptions);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setShowOptions(false);
      setBorderRadius("12px");
      setTransform("rotate(0deg)");
      setBorderColor("#b8b8b8")
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className="selectContainer">
      <div onClick={toggle} id="select" className="select" style={{ borderRadius: borderRadius, borderColor: borderColor }}>
        {selected && selected.image ?
          <img alt={selected.content} className="optionImg" src={selected.image} width={30} height={30}></img>
        :
          null
        }
        {selected ?
          <span>{selected.content}</span>
        : 
          <span>{defaultText}</span>
        }
        <FaChevronDown id="iconDown" className="iconDown" style={{ position: "absolute", right: 10, transition: "0.2s", transform: transform }}/>
      </div>
      <div style={{ display: showOptions ? "block" : "none" }} className="options">
        {options.map((option, i) => {
          return(
            <div onClick={() => {
              setValue(option)
              toggle()
            }} key={i} style={{ filter: selected === option ? "brightness(0.9)" : null }} className="option">
              {option.image ? <img alt={option.content} className="optionImg" src={option.image} width={30} height={30}></img> : null}
              {option.content}
            </div>
          )
        })}
      </div>
      <style>{`
        .select:hover {
          filter: brightness(0.9);
        }

        .option:hover {
          filter: brightness(0.9);
        }

        .option:last-child {
          border-radius: 0px 0px 12px 12px;
        }

        .optionImg {
          margin-right: 10px;
        }

        .options {
          position: absolute;
          z-index: 60;
        }
      `}</style>
    </div>
  );
};

export default Select;