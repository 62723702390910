import React, { Component } from "react";
import MessageBox from "../components/MessageBox";
import { IoSend, IoChevronBack, IoLockOpen, IoLockClosed, IoTrash } from 'react-icons/io5'
import { client } from "../oauth";
import { ThreeDots } from "react-loader-spinner";
import ContentLoader from 'react-content-loader'
import { Helmet } from "react-helmet";
let socket = new WebSocket('wss://catnip-boggy-neon.glitch.me');

export default class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ticket: {
        messages: []
      },
      title: "",
      message: "",
      user: "",
      loading: true,
      locked: null
    }
    this.scrollContentRef = React.createRef();
    this.codeToString = this.codeToString.bind(this)
    this.send = this.send.bind(this)
    this.load = this.load.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.delete = this.delete.bind(this)
    this.toggleLock = this.toggleLock.bind(this)
    this.setupSocketListeners = this.setupSocketListeners.bind(this)
  }

  async delete() {
    this.setState({ loading: true })
    const urlparams = new URLSearchParams(window.location.search)
    const data = {
      type: "delete",
      channel: urlparams.get("id")
    }
    socket.send(JSON.stringify(data))
    fetch(`https://catnip-boggy-neon.glitch.me/feedbacks/${urlparams.get("id")}`, {
      method: "DELETE",
      headers: {
        "Access-Key": "l8KsHVkC4Xa3v11"
      }
    }).then(() => {
      window.location = window.location.origin
    })
  }

  async toggleLock() {
    this.setState({ locked: !this.state.locked, loading: true })
    const urlparams = new URLSearchParams(window.location.search)
    const data = {
      type: "lock",
      channel: urlparams.get("id")
    }
    socket.send(JSON.stringify(data))
    fetch(`https://catnip-boggy-neon.glitch.me/feedbacks/${urlparams.get("id")}`, {
      method: "PATCH",
      headers: {
        "Access-Key": "l8KsHVkC4Xa3v11",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ locked: this.state.locked })
    }).then(() => {
      this.setState({ loading: false })
    })
  }

  async send() {
    if(!this.state.message || (this.state.locked && !["682554962693062707", "708579930979565588"].includes(this.state.user.id))) return
    const messageContent = this.state.message
    this.setState({ message: "" }, () => {
      document.getElementById("textarea").style.height = "1.5rem"
    })
    const urlparams = new URLSearchParams(window.location.search)
    const user = this.state.user
    const message = {
      content: messageContent,
      author: user,
      timestamp: Date.now()
    }
    const data = {
      type: "message",
      channel: urlparams.get("id"),
      message: message
    }
    const { ticket } = this.state
    ticket.messages.push({ ...message, sending: true })
    this.setState({ ticket })
    //if(socket.readyState !== socket.OPEN) socket = new WebSocket("wss://catnip-boggy-neon.glitch.me")
    socket.send(JSON.stringify(data))
    //fetch(`https://catnip-boggy-neon.glitch.me/feedbacks/${urlparams.get("id")}`, {
    //  method: "POST",
    //  headers: {
    //    "Content-Type": "application/json",
    //    "Access-Key": "l8KsHVkC4Xa3v11"
    //  },
    //  body: JSON.stringify(message)
    //}).then(() => {
    //  socket.send(JSON.stringify(data))
    //  this.load()
    //})
  }

  async load() {
    const urlparams = new URLSearchParams(window.location.search)
    const user = await client.getUser(localStorage.getItem("token"))
    this.setState({ user: user })
    const userID = urlparams.get("id").split(".")[1]
    if(!["708579930979565588", "682554962693062707"].includes(user.id) && userID !== user.id) return window.location = window.location.origin
    fetch(`https://catnip-boggy-neon.glitch.me/feedbacks/${urlparams.get("id")}`, {
      headers: {
        "Access-Key": "l8KsHVkC4Xa3v11"
      }
    }).then(res => res.json()).then(async data => {
      const title = `${this.codeToString(data.ticket.id.split(".")[0])} - ${this.codeToString(data.ticket.bot)}`
      this.setState({ ticket: data.ticket, title: title, loading: false, locked: data.ticket.locked })
    })
  }

  handleKeyDown(e) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.send();
    }
  }

  setupSocketListeners(id) {
    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data)
      if(data.type === "message") {
        //const message = data.message
        if(data.channel === id) {
          this.load()
        }
      } else if(data.type === "messageSent") {
        const { ticket } = this.state
        const messageWithSending = { ...data.message, seding: true }
        const index = ticket.messages.findIndex(message => message.timestamp === messageWithSending)
        ticket.messages.splice(index, 1)
        ticket.messages.splice(index, 0, data.message)
        this.setState({ ticket })
      } else if(data.type === "delete") {
        if(data.channel === id) {
          window.location = window.location.origin
        }
      } else if(data.type === "lock") {
        if(data.channel === id) {
          this.setState({ locked: !this.state.locked })
        }
      }
    })

    socket.addEventListener('close', (event) => {
      if (event.wasClean) {
        console.log(`WebSocket bağlantısı kapatıldı, kod: ${event.code}, neden: ${event.reason}`);
      } else {
        console.error('WebSocket bağlantısı beklenmedik şekilde kesildi.');
        // Bağlantı beklenmedik şekilde kesilirse otomatik yeniden bağlanma işlemi yapın.
    
        // Şimdiye kadar sadece bir kere yeniden bağlanmayı deneyin.
        setTimeout(() => {
          socket = new WebSocket("wss://catnip-boggy-neon.glitch.me");
          console.log("bağlandı!?")
          this.setupSocketListeners(id)
        }, 1000); // 1 saniye sonra yeniden bağlanmayı deneyin.
      }
    });
    
    socket.addEventListener('error', (error) => {
      console.error('WebSocket hatası:', error);
      // Hata durumlarını ele alın ve gerekirse bağlantıyı kapatın.
    });
  }

  codeToString(code) {
    switch (code) {
      case "sinek":
        return "Sinek BOT"
      case "marti":
        return "Martı BOT"
      case "oneri":
        return "Öneri"
      case "geribildirim":
        return "Geribildirim"
      default:
        return
    }
  }

  componentDidMount() {
    this.load()
    const urlparams = new URLSearchParams(window.location.search)
    this.setupSocketListeners(urlparams.get("id"));
  }

  componentDidUpdate() {
    this.scrollContentRef.current.scrollTop = this.scrollContentRef.current.scrollHeight;
  }

  render() {
    const { title } = this.state;

    return(
      <div className="container">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <h1 className="title">{this.state.title ? this.state.title : 
        <ContentLoader 
          speed={3}
          width={292}
          height={39}
          viewBox="0 0 292 39"
          backgroundColor="rgba(0, 0, 0, 0.2)"
          foregroundColor="rgba(0, 0, 0, 0.3)"
          style={{ borderRadius: 12 }}
        >
          <rect x="-282" y="-35" rx="0" ry="0" width="790" height="203" />
        </ContentLoader>
        }</h1>
        <div className="card">
          <ThreeDots color="#000" visible={this.state.loading} />
          <div onClick={() => {window.location = `${window.location.origin}/`}} className="backButton">
            <div className="backButtonContent">
              <IoChevronBack className="backButtonIcon" size={20}/>
              Geri
            </div>
          </div>
          <div style={{ display: ["682554962693062707", "708579930979565588"].includes(this.state.user.id) ? "flex" : "none" }} className="buttonContainer">
            <div onClick={this.toggleLock} className="lockButton">
              <div className="backButtonContent">
                {this.state.locked ? <IoLockClosed className="backButtonIcon" /> : <IoLockOpen className="backButtonIcon" /> }
                <span className="buttonText">{this.state.locked ? "Kilidi Aç" : "Kilitle"}</span>
              </div>
            </div>
            <div onClick={this.delete} className="deleteButton">
              <div className="backButtonContent">
                <IoTrash className="backButtonIcon"/>
                <span className="buttonText">Sil Bakalım</span>
              </div>
            </div>
          </div>
          <div style={{ display: this.state.loading ? "none" : "flex" }} ref={this.scrollContentRef} className="scroll-content">
            {this.state.ticket.messages.sort((a, b) => a.timestamp - b.timestamp).map((message, i) => {
              return <MessageBox messages={this.state.ticket.messages.sort((a, b) => a.timestamp - b.timestamp)} message={message} key={i}/>
            })}
          </div>
          <div style={{ display: this.state.loading ? "none" : "flex" }} className="textAreaContainer">
            <div className="textAreaWrapper">
              <textarea disabled={this.state.locked && !["708579930979565588", "682554962693062707"].includes(this.state.user.id)} id="textarea" onKeyDown={this.handleKeyDown} onChange={e => {
                this.setState({ message: e.target.value })
                if (e.target.scrollHeight <= 80) {
                  e.target.style.height = "1.5em";
                  e.target.style.overflowY = "hidden";
                } else if (e.target.value.trim() === "") {
                  e.target.style.height = "1.5em";
                  e.target.style.overflowY = "hidden";
                } else {
                  e.target.style.overflowY = "scroll";
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }
              }} value={this.state.message} spellCheck="false" className="textArea" placeholder={this.state.locked && !["708579930979565588", "682554962693062707"].includes(this.state.user.id) ? "En kısa sürede yanıtlayacağız." : "Bir yanıt yazın..."}></textarea>
              <IoSend onClick={this.send} size={30} color="#555555" className="textAreaIcon"/>
            </div>
          </div>
        </div>
        <style>{`
          .card {
            justify-content: center;
          }
          
          .deleteButton {
            background: #F83B3B;
          }

          .lockButton {
            background: #000;
            margin-right: 10px;
          }

          .buttonText {
            margin-right: 3px;
          }

          .lockButton, .deleteButton {
            border-radius: 17px;
            z-index: -1;
            font-weight: 700;
            cursor: pointer;
            transition: 0.2s;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 15px 66px 15px;
          }

          .lockButton:hover, .deleteButton:hover {
            filter: brightness(0.4);
          }

          .buttonContainer {
            position: absolute;
            right: 0;
            top: -38px;
            display: flex;
            z-index: -1;
          }

          .textArea {
            resize: none;
            font-family: Nunito;
            font-size: 15px;
            width: 100%;
            padding: 20px;
            outline: none;
            background: #b8b8b8;
            border-radius: 12px 0px 0px 12px;
            border: none;
            overflow-y: hidden;
            height: 1.5em;
            max-height: 3em;
          }

          .textArea::-webkit-scrollbar {
            display: none;
          }

          .textAreaIcon {
            position: relative;
            cursor: pointer;
            transition: 0.2s;
            padding: 10px;
          }

          .textAreaIcon:hover {
            filter: brightness(0.8);
          }

          .textAreaWrapper {
            background: #b8b8b8;
            border-radius: 12px;
            display: flex;
            align-items: center;
            width: 85%;
          }

          .textArea::placeholder {
            color: #555555;
            font-weight: 700;
          }
        `}</style>
      </div>
    )
  }
}