import { Component } from "react";
import { FaDiscord } from 'react-icons/fa'
import { client } from "../oauth";

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      load: true
    }
  }

  componentDidMount() {
    const url = new URLSearchParams(window.location.search)
    if(url.has("code")) {
      this.setState({ load: false })
      client.getAccessToken(url.get("code")).then(res => {
        localStorage.setItem("token", res.accessToken)
        const windowLocation = window.opener.location
        window.opener.location = windowLocation
        window.close()
      })
    }
  }

  render() {
    if(!this.state.load) return null
    return(
      <div className="loginContainer">
        <div className="loginWrapper">
          <h1 className="loginTitle">Giriş Yap</h1>
          <button onClick={() => {
            window.open(
              'https://discord.com/api/oauth2/authorize?client_id=1143538313320464437&redirect_uri=https%3A%2F%2Fgeribildirim.vercel.app%2F&response_type=code&scope=identify%20guilds%20guilds.members.read',
              '',
              'toolbar=0,scrollbars=2,location=0,statusbar=1,menubar=0,resizable=0,width=500,height=775,left=200,top=100'
            )
          }} className="loginButton"><FaDiscord size={20} style={{ marginRight: 5 }}/> Discord ile giriş yap</button>
        </div>
        <style>{`
          .rectangle {
            display: block;
            width: 140%;
          }

          body {
            background: #313338;
          }

          .loginContainer {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
          }

          .loginWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: absolute;
            top: 30%;
          }

          .loginTitle {
            font-family: Montserrat;
            margin-bottom: 40px;
          }

          .loginButton {
            padding: 10px;
            font-family: Nunito;
            font-size: 17px;
            outline: none;
            border: none;
            border-radius: 5px;
            background: #5865F2;
            color: #fff;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.2s;
            user-select: none;
          }

          .loginButton:hover {
            filter: brightness(0.8);
          }

          .loginButton:active {
            filter: brightness(0.7);
          }
        `}</style>
      </div>
    )
  }
}