import { Component } from "react";
import { client } from "../oauth";
import TicketContainer from "../components/TicketContainer";
import emoji from '../thinking-face.png'
import { ThreeDots } from "react-loader-spinner";
let socket = new WebSocket('wss://catnip-boggy-neon.glitch.me');

export default class Feedbacks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tickets: [],
      loading: true,
      users: [],
      user: ""
    }
    this.load = this.load.bind(this)
  }
  
  load(user) {
    fetch(`https://catnip-boggy-neon.glitch.me/users/${user.id}/feedbacks`, {
      headers: {
        "Access-Key": "l8KsHVkC4Xa3v11"
      }
    }).then(res => res.json()).then(data => {
      this.setState({ tickets: data.tickets, loading: false })
    })
  }

  componentDidMount() {
    client.getUser(localStorage.getItem("token")).then(user => {
      this.load(user)
      this.setState({ user })
    })

    socket.addEventListener("message", (e) => {
      const data = JSON.parse(e.data)
      if(data.type === "ticket") {
        this.load(this.state.user)
      }
    })

    socket.onclose = () => {
      setTimeout(() => {
        socket = new WebSocket('wss://catnip-boggy-neon.glitch.me');
      }, 1000);
    }
  }

  render() {
    return(
      <div className="container">
        <h1 className="title">Geribildirimler</h1>
        <div className="card">
          <ThreeDots
            color="#000"
            visible={this.state.loading}
          />
          <div onClick={() => window.location = `${window.location.origin}/createRequest`} className="newRequestButton">
            Yeni Oluştur
          </div>
          <div style={{ display: (this.state.tickets.length > 0 || this.state.loading) ? "none" : "block" }} className="warning">
            <h3 style={{ margin: 10 }}>Gösterilecek geribildirim bulunamadı</h3>
            <img draggable="false" alt="thinking face" width={70} src={emoji}></img>
          </div>
          <div style={{ display: this.state.loading ? "none" : "flex" }} className="scroll-content">
            {this.state.tickets.map((ticket, i) => {
              return(
                <TicketContainer users={this.state.users} key={i} ticket={ticket} />
              )
            })}
          </div>
        </div>
        <style>{`
          .card {
            justify-content: center;
          }

          .warning {
            color: #000;
            position: absolute;
            text-align: center;
            font-family: Montserrat;
            font-size: 20px;
          }

          .newRequestButton {
            background: #000;
            padding: 10px 20px 66px 20px;
            border-radius: 17px;
            position: absolute;
            top: -38px;
            right: 0;
            z-index: -1;
            font-weight: 700;
            cursor: pointer;
            transition: 0.2s;
            user-select: none;
          }

          .newRequestButton:hover {
            filter: brightness(0.4);
          }
        `}</style>
      </div>
    )
  }
}